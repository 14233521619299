@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rancho&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Urbanist, system-ui, Avenir, Helvetica, Arial, sans-serif;
  color-scheme: light dark;
}

:root {
  --bg-color: #f4f3ff;
  --btn-color: #646cff;
  --dark-color: #242424;
  --helper-color: #99b9ff;
  color-scheme: light only;
}

html {
  font-size: 62.5%;
}

/*? base style */

body {
  margin: 0;
  /* display: flex;
  place-items: center; */
  /* min-width: 32rem; */
  min-height: 100vh;
}

h1 {
  font-size: 5.4rem;
  line-height: 1.1;
  font-weight: bold;
}

p,
li,
label,
input,
textarea {
  font-size: 1.8rem;
  line-height: 1.56;
  letter-spacing: 0.1rem;
  word-spacing: 0.06rem;
}

a {
  font-weight: 500;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  color: var(--btn-color);
  text-decoration: inherit;
}

li {
  list-style: none;
}

/* react toastify css  */

.toastBody {
  font-family: "Atlas Grotesk Web", Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
}

/*? layout Rule  */

.container {
  max-width: 140rem;
  padding: 4rem 2.4rem;
  margin: 0 auto;
}

.grid {
  display: grid;
  gap: 6.4rem;
}

.grid-two-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid-four-cols {
  grid-template-columns: repeat(4, 1fr);
}

/*? Module Rules / Reusable */
button {
  text-transform: capitalize;
  padding: 1rem 2.2rem;
  font-size: 1.7rem;
  font-weight: 500;
  background-color: var(--btn-color);
  border-radius: 0.8rem;
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  -ms-border-radius: 0.8rem;
  -o-border-radius: 0.8rem;
  border: 0.1rem solid transparent;
  cursor: pointer;
  letter-spacing: 0.1rem;
  transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  -ms-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
}

.secondary-btn {
  background-color: transparent;
  box-shadow: inset 0 0 0 0.2rem var(--btn-color);
  margin-left: 3.2rem;
}

/*? state rules  */

button:hover {
  box-shadow: inset 0 0 0 0.2rem var(--bg-color);
  /* border: 0.2rem solid #fff; */
}

a:hover,
a.active {
  color: #535bf2;
}

/* pseduo elements  */
.main-heading {
  
  position: relative;
  text-transform: capitalize;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -30%;
    width: 50%;
    height: 0.5rem;
    background-color: var(--btn-color);
  }
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover,
  a.active {
    color:  #d20000;
  }
  button {
    background-color: #f9f9f9;
  }
}

