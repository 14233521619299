
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Team Section */
  .section-white {
    background-color: #ffffff;
    padding: 50px 0;
    text-align: center;
  }
  
  .section-white h2 {
    font-size: 2.5rem;
    color: #333;
  }
  
  /* Team Cards and Columns */
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .column {
    flex: 1;
    max-width: 300px;
  }
  
  .card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:400px;
    overflow-wrap: break-word;
  }
  
  .card img {
    width: 50%;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .card h2 {
    font-size: 1.5rem;
    color: #333;
  }
  
  .card p.title {
    font-size: 1.2rem;
    color: #777;
  }
  
  .card p {
    color: #555;
  }
  
  .social-icons {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .card:hover .social-icons {
    opacity: 1;
  }
  
  .social-icons a {
    color: #007bff;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .social-icons a:hover {
    color: #0056b3;
  }


.card {
    /* ... (existing styles) */
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .card img {
    width: 80%; /* Adjust the width of the image as needed */
    border-radius: 90%;
    margin-bottom: 20px;
    max-width: 200px;
  }
  
  .social-icons {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .card:hover .social-icons {
    opacity: 1;
  }
  
  .social-icons a {
    color: #007bff;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .social-icons a:hover {
    color: #0056b3;
  }

  .social-icons a {
    color: #007bff;
    text-decoration: none;
    margin: 0 10px;
    font-size: 24px; /* Adjust the font-size as needed */
  }
  
  .social-icons a:hover {
    color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .social-icons {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      opacity: 1; /* Fixed icons are always visible on mobile */
    }
    
    .social-icons a {
      color: #007bff;
      text-decoration: none;
      margin: 0 10px;
      font-size: 24px; /* Adjust the font-size as needed */
    }
  }