* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Urbanist, system-ui, Avenir, Helvetica, Arial, sans-serif;
    color-scheme: light dark;
  }

  .overlay {
    position: fixed; /* Positioning relative to the viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1000; /* Ensure it sits above other content */
  }
  
  :root {
    --bg-color: #f4f3ff;
    --btn-color: #646cff;
  }
  
  /* Base styles */
  body {
    min-height: 100vh;
    font-size: 16px; /* Base font size */
  }
  
  h1 {
    font-size: 5.4rem;
    line-height: 1.1;
    font-weight: bold;
  }
  
  p,
  li,
  label,
  input,
  textarea {
    font-size: 1.8rem;
    line-height: 1.56;
  }
  
  a {
    font-weight: 500;
    font-size: 1.8rem;
    color: var(--btn-color);
    text-decoration: inherit;
  }
  
  li {
    list-style: none;
  }
  
  /* Layout */
  .container {
    max-width: 140rem;
    padding: 4rem 2.4rem;
    margin: 0 auto;
  }
  
  .grid {
    display: grid;
    gap: 6.4rem;
  }
  
  .grid-two-cols {
    grid-template-columns: repeat(2, 1fr);
  }
  
  /* Buttons */
  button {
    text-transform: capitalize;
    padding: 1rem 2.2rem;
    font-size: 1.7rem;
    font-weight: 500;
    background-color: var(--btn-color);
    border-radius: 0.8rem;
    border: 0.1rem solid transparent;
    cursor: pointer;
    transition: border-color 0.3s;
  }
  
  .section-contact {
    padding: 0;
  }
  
  .section-contact .contact-content h1 {
    margin-bottom: 3.2rem;
  }
  
  .section-contact .container {
    padding-top: 3.2rem;
  }
  
  .section-contact .contact-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .section-contact .contact-img img {
    max-width: 100%;
    height: auto;
  }
  
  .section-contact .section-form form {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
  }
  
  .section-contact .section-form label {
    display: block;
    margin-bottom: 1.2rem;
    text-transform: capitalize;
  }
  
  .section-contact .section-form input,
  .section-contact .section-form textarea {
    width: 100%;
    padding: 0.4rem 0.6rem;
  }
  
  .contact-img iframe,
  .section-form input,
  .section-form textarea {
    width: 100%; /* Make iframe and form elements responsive */
  }

  @media (min-width: 768px) {
    .grid-two-cols {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for wider screens */
    }
  }
  
  /* Small devices (phones, less than 768px) */
  @media (max-width: 767px) {
    .grid-two-cols {
      grid-template-columns: 1fr; /* Stack elements vertically on small screens */
    }
  
    .font-heading {
      font-size: 3xl; /* Adjust font size for smaller screens */
    }
  }

  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 4rem;
    }
  
    p,
    li,
    label,
    input,
    textarea {
      font-size: 1.6rem;
    }
  }
  
  @media screen and (max-width: 790px) {
    .grid-two-cols {
      grid-template-columns: 1fr;
    }
  }

  /* * Media Queries for Responsive Design  */
@media (max-width: 768px) {
  .grid-two-cols {
    grid-template-columns: 1fr;
  }

  .contact-img img {
    width: 100%;
  }

  .section-form form {
    width: 100%;
  }

  .section-form label {
    margin-bottom: 1rem;
  }

  button {
    padding: 0.8rem 1.6rem;
    font-size: 1.4rem;
  }
}

@media (max-width: 480px) {
  /* Adjust styles for screens up to 480px wide */
  h1 {
    font-size: 3.4rem;
  }

  p,
  li,
  label,
  input,
  textarea {
    font-size: 1.4rem;
  }
  
  button {
    padding: 0.6rem 1.2rem;
    font-size: 1.2rem;
  }
}

/* Add more media queries as needed for even smaller screen sizes */
@media (max-width: 375px) {
  /* Adjust styles for screens up to 375px wide */
  h1 {
    font-size: 2.8rem;
  }

  p,
  li,
  label,
  input,
  textarea {
    font-size: 1.2rem;
  }
  
  button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
} 
