/* AboutUsBox.css */


.about-us-box {
    /* background-color: #cdd7d1; */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin: 20px;
  }

  .text-content{
    font-family: Arial, Helvetica, sans-serif;
    font-size: large ;
    text-align:start;
    padding: 5%;
  }

  .header{
    font-size: 3em;
    text-align: center;
  }
  
  h2 {
    color: #ffffff;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .image-container {
    flex: 1;
    margin-right: 20px;
  }
  
  .gtlogo {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  }

  .text-container {
    flex: 2;
    margin-left: 5%;
  }
  
  p {
    color: #555;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  @media only screen and (max-width: 550px) {
    .content {
      display: grid;
      grid-template-columns: auto;
      align-items: center;
    }
    
    .image-container {
      margin-right: 20px;
    }
    
    .gtlogo {
      width: 100%;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    }
  
    .text-container {
      margin-left: 5%;
    }

  }