/* SponsorCarousel.css */

.sponsor-carousel {
    text-align: center;
    margin: 20px;
    
  }
  
  h2 {
    color: #000000;
    font-size: 1.5rem;
    margin-bottom: 20px;
    margin-left: 0%;
  }
  
  .slick-slide {
    margin: 0 10px;
  }
  
  .slogo,.slogo2,.slogo3 {
    width: 60%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .slogo1{
    width: 30%;
    margin-left: 10px;
  }
  