
body {
    margin: 0; /* Remove default margin to ensure full page coverage */
    padding: 0; /* Remove default padding to ensure full page coverage */
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .header {
    margin-top: 20px;
    font-size: 30px; /* Adjust the font size as needed */
    font-weight: bold;
    color: wheat; /* Set the color of the heading */
    text-align: center;
    padding-top: 75px;
  }
  
  body {
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-size: cover;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif 
  }
  
  .header {
    margin-top: 20px;
    font-size: 30px;
    font-weight: bold;
    color: wheat;
    text-align: center;
  }
  .event-card-container {
    display: grid;
    gap: 50px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    padding: 40px;
    min-height: 100vh;
    margin: 30px;
  }
  
  .event-card {
    max-width: 100%;
    display: flex; /* Added to enable flexbox */
    flex-direction: column; /* Optional: To make the image and content stack vertically */
    align-items: center; /* Center content horizontally */
    background-color: rgba(1, 8, 43, 0.8);
    color: aliceblue;
    padding: 0px;
    font-size: 30px;
    min-height: 75vh;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  }
  
  .event-card:hover {
    background-color: #299afd;
    color: white;
    transform: scale(1.05);
  }
  
  .image {
    margin: 20px;
    max-width: 100%;
    max-height: 100%; /* Ensure the image doesn't exceed the container width */
  }
  
  .text-container {
    padding: 0px;
    text-align: center;
    margin:20px;
  }
  
  .text-container span,
  .text-container p {
    display: block;
    margin-bottom: 8px; /* Adjust as needed for spacing between lines */
  }
  
  /* EventCard.css */
  .text-container {
    padding: 5px;
  }
  
  .desc,.size,.fee{
    text-align: left;
    font-size: 20px;
  }
  
  .register-button {
    background-color: #003c81;
    border-radius: 20px;
    color: white;
    padding: 10px 20px 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .register-button:hover {
    background-color:  rgba(81, 0, 97, 0.8);
  }
  
  
  