

.slider {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin-top: -2%;
  }
  
  .image {
    width: 1300px;
    height: 500px;
    border-radius: 10px;
  }
  
  .right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 1.5rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 1.5rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .slide {
    opacity: 0;
    transition: opacity 0.5s ease; /* Adjust transition for smoother opacity change */
  }
  
  .slide.active {
    opacity: 1;
    transition: opacity 0.5s ease, transform 0.5s ease; /* Add transform to the transition */
    transform: scale(1.08); /* Apply transform on active slide */
  }
  
  